@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  src: local(""),
    url("../fonts/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 600;
  src: local(""),
    url("../fonts/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  src: local(""),
    url("../fonts/Gilroy-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  src: local(""),
    url("../fonts/Gilroy-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 900;
  src: local(""),
    url("../fonts/Gilroy-ExtraBold.ttf") format("truetype");
}